.DateRange__wrap {
    display: flex;
    justify-content: left;
    height: 55px;
    z-index: 100;
    position: relative;
}
.DateRange__wrap h4 {
    line-height: 25px;
    margin: 1rem 0.5rem 1rem 0;
}
.rdrCalendarWrapper {
    width: 350px;
    overflow: hidden;
    height: fit-content;
    border-radius: 10px;
}
.rdrDateDisplayWrapper,
.rdrNextPrevButton {
    background-color: #439d9066 !important;
}
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
    color: #439d90 !important;
}
.rdrDayToday .rdrDayNumber span:after {
    background: #439d90 !important;
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDateDisplayItemActive {
    border-color: #439d90 !important;
}
.rdrMonth {
    padding: 0 0.833em 0.833em 0.833em;
}
.DateRange--minimised .rdrMonthAndYearWrapper,
.DateRange--minimised .rdrMonths {
    display: none;
}
.DateRange--expanded .rdrCalendarWrapper {
    box-shadow: 0px 10px 15px -9px rgb(0 0 0 / 50%);
}
.DateRange--expanded .rdrMonthAndYearWrapper,
.DateRange--expanded .rdrMonths {
    display: flex;
    background: #fff;
}
.HighchartsReact__wrap {
    margin: 2em 0;
}

.toggle-plotline {
    display: flex;
    justify-content: left;
    height: 25px;
    z-index: 100;
    position: relative;
    gap: 0 5px;
    margin-top: 10px;
}
.toggle-plotline h4 {
    line-height: 25px;
    margin: 0;
    font-size: 14px;
}
.toggle-plotline button {
    border: none;
    border-radius: 100px;
    padding: 0px 12px;
    font-size: 12px;
    outline: none;
    background-color: #439d9066;
    color: #439d90;
    color: white;
}
.toggle-plotline button.on {
    background-color: #439d90;
    color: white;
}

.node-details {
    border: none;
    background-color: #088476;
    border-radius: 100px;
    color: white;
    outline: none;
    font-size: 16px;
    padding: 0.2em 2.004em;
    cursor: pointer;
    line-height: 24px;
}
