/* Smartphones ----------- */

@media screen and (max-width: 380px) {
    .node-map-legend {
        max-width: 65vw;
        font-size: 10px !important;
    }

    .sensor-footer-legend {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 480px) {
    .node-map-legend {
        max-width: 65vw;
        font-size: 14px;
    }

    .sensor-footer-legend {
        font-size: 13px;
    }
}


.slide-pane__overlay {
    z-index: 99999 !important;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

    .info h4 {
        margin: 0 0 5px;
        color: #777;
    }

.legend {
    text-align: left;
    line-height: 18px;
    color: #555;
}

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin-right: 8px;
        opacity: 0.7;
    }

/* Datepicker polishing */
.react-daterange-picker__inputGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.react-date-picker__inputGroup__divider {
    line-height: 1.8em;
}

.leaflet-tooltip-marker-offset {
    top: -30px;
}
