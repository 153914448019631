.sq-space-custom-styling h2, h4 {
    color: #439d90;
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
}

.sq-space-custom-styling h2 {
    font-size: calc((2.8 - 1) * 1.2vw + 1rem);
}

.sq-space-custom-styling h4 {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
}

.slide-pane__header {
    background-color: #d4ebe8 !important;
    border-bottom: none !important;
}

.slide-pane__content {
    position: static;
    width: 100%;
    height: 100%;
    padding: 0;
}

.slide-pane__title {
    color: #088476 !important;
    font-weight: 400 !important;
    width: calc(100% - 190px);
}

.sq-space-custom-styling {
    background-color: #eaf6f4;
}

    .sq-space-custom-styling p {
        font-weight: 400;
        font-family: Poppins;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0em;
        text-transform: none;
        line-height: 1.8em;
        font-size: 1rem;
        line-height: 1.8;
        -webkit-font-smoothing: antialiased;
    }

.key-styling {
    font-weight: 400;
    font-family: Poppins;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.8em;
    font-size: 0.8em;
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
}

.legend-styling {
    color: white;
    font-weight: 600;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-family: Poppins;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.8em;
    font-size: 0.7em;
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
}

.legend-styling td {
    text-align: center;
}